import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  preloadLegacy(event) {

    // clicked info
    const name = event.target.dataset.name;
    const subtitle = event.target.dataset.subtitle;
    const edition = event.target.dataset.edition;
    const night = event.target.dataset.night;
    const jpName = event.target.dataset.jpname;
    const jpSubtitle = event.target.dataset.jpsubtitle;

    // get target elements
    const targetName = document.getElementById("event_name");
    const targetEdition = document.getElementById("event_edition");
    const targetNight = document.getElementById("event_night");
    const targetSubtitle = document.getElementById("event_subtitle");
    const targetJpName= document.getElementById("event_japanese_name");
    const targetJpSubtitle = document.getElementById("event_japanese_subtitle");

    // update target values
    targetName.value = name;
    targetJpName.value = jpName;
    targetJpSubtitle.value = jpSubtitle;

    if (edition !== null && edition !== undefined && edition !== "") {
      targetEdition.value = (parseInt(edition, 10) + 1);
    }

    if (night !== null && night !== undefined && night !== "") {
      targetNight.value = (parseInt(night, 10) + 1);
    }

    if (subtitle !== null && subtitle !== undefined && subtitle !== "") {
      targetSubtitle.value = subtitle;
    }

  }

  handleChange() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this._handleChange();
    }, 800);
  }

  // This method is called when the option input changes
  _handleChange() {

    const selectedValue = document.getElementById("event_promoter_id").value;

    switch (selectedValue) {
      case "2":
        document.getElementById("decoder_njpw").removeAttribute("hidden");
        break;
      case "8": // DDT
      case "12": // TJPW
      case "16": // Ganbarre
        document.getElementById("decoder_ddt").removeAttribute("hidden");
        break;
      case "11":
        document.getElementById("decoder_stardom").removeAttribute("hidden");
        break;
      case "52":
        document.getElementById("decoder_dragongate").removeAttribute("hidden");
        break;
      case "46":  // michinoku
        document.getElementById("decoder_michinoku").removeAttribute("hidden");
        break;
      case "99":  // michinoku
        document.getElementById("decoder_iceribbon").removeAttribute("hidden");
        break;
      case "9":  // noah
        document.getElementById("decoder_noah").removeAttribute("hidden");
        break;
      case "242":  // awg
        document.getElementById("decoder_awg").removeAttribute("hidden");
        break;
      case "13":  // awg
        document.getElementById("decoder_bjw").removeAttribute("hidden");
        break;
      case "86":  // awg
        document.getElementById("decoder_jto").removeAttribute("hidden");
        break;
    }

  }
}
